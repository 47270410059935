<template>
	<div class="news">
		<div class="news_main">
			<div class="header">
				<img src="@/assets/images/news/banner.png" alt="">
				<div class="inner animated fadeIn">
					<p>{{$t('lang.home.nav2')}}</p>
					<p>{{$t('lang.news.header1')}}</p>
				</div>
			</div>
			<div class="news_cont">
				<ul class="news_box">
					<li class="animated fadeInUp" v-for="(item,index) in newsData" :key="index">
						<div class="newsImg" @click="goDetail(item.link)">
							<img :src="item.img" />
						</div>
						<div class="news_text">
							<div class="text_cont">
								<h2>{{item.title}}</h2>
								<p>{{item.time}}</p>
								<router-link :to="item.link" target="_blank"></router-link>
							</div>
						</div>
					</li>
				</ul>
			</div>
		</div>
		<router-view />
	</div>
</template>

<script>
export default {
  name: "news",
  data() {
    return {
      newsData: [
        {
          img: require("@/assets/images/news/1.jpg"),
          title: "“五月鲜花五支歌”新歌发布会 ",
          time: "2019.11.07",
          link: "/news/detail/1"
        },
        {
          img: require("@/assets/images/news/2.jpg"),
          title:
            "文献纪录片《那岁月刻骨铭心》赠送仪式暨翰林苑爱国教育基地开馆活动在京举行",
          time: "2019.10.18",
          link: "/news/detail/2"
        },
        {
          img: require("@/assets/images/news/3.png"),
          title: "“翰林院音乐录音棚”开棚仪式在京举行",
          time: "2017.10.15",
          link: "/news/detail/3"
        }
      ]
    };
  },
  methods: {
    goDetail(url) {
      if (url) {
        this.$router.push(url);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.news_main {
  width: 100%;
  height: 100%;
  position: relative;
  .news_cont {
    width: 100%;
    height: 100%;
    background: #061319;
    .news_box {
      width: 100%;
      height: 100%;
      overflow: hidden;
      padding: 120px 15.625vw 100px;
      > li {
        width: 100%;
        height: 272px;
        margin: 0px 0 50px;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        .newsImg {
          width: 482px;
          height: 272px;
          overflow: hidden;
          display: flex;
          align-items: center;
          justify-content: center;
          > img {
            width: 100%;
            height: 100%;
            transition: all 2s ease-in-out;
            cursor: pointer;
          }
          > img:hover {
            transform: scale(1.2);
          }
        }
        .news_text {
          width: calc(100% - 482px);
          height: 272px;
          color: #ffffff;
          text-align: left;
          margin-left: 70px;
          display: flex;
          align-items: center;
          justify-content: flex-start;
          overflow: hidden;
          .text_cont {
            > h2 {
              width: 100%;
              font-size: 26px;
              line-height: 36px;
              font-family: "OpenSansRegular";
              margin: 0;
            }
            > p {
              width: 100%;
              font-size: 18px;
              font-family: "OpenSansLight";
              margin: 10px 0px 18px;
            }
            > a {
              display: block;
              width: 128px;
              height: 40px;
              background: url("../assets/images/news/next.png") no-repeat center;
              background-size: 100%;
              opacity: 0.8;
              &:hover {
                opacity: 1;
                cursor: pointer;
              }
            }
          }
        }
      }
    }
  }
}
</style>

